import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faLinkedin, faMedium,
} from '@fortawesome/free-brands-svg-icons';

import Link from '../elements/link';

const TeamImg = styled(Img)`
  border-radius: 200px;
  margin-bottom: 2em;
  padding: 16px;
`;

const TeamRow = styled(Row)`
  margin: 16px 0;
  h3{
    margin: 16px 0;
    .function{
      font-weight: normal;
      font-size: 22px;
    }
  }
  p{
    margin-bottom: 16px;
  }
`;

const TeamMembers = () => (
  <StaticQuery
    query={graphql`
    query {
      oliviercorradi: file(relativePath: { eq: "team/olivier-corradi-tmrow.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
  
      olivierbaumann: file(relativePath: { eq: "team/olivier-baumann-tmrow.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      trevorhinkle: file(relativePath: { eq: "team/trevor-hinkle-tmrow.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      felixqvist: file(relativePath: { eq: "team/felix-qvist-tmrow.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      martincollignon: file(relativePath: { eq: "team/martin-collignon-tmrow.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

    }
  `}
    render={data => (
      <GridContainer>

        <TeamRow>
          <Col md={4}>
            <TeamImg fluid={data.oliviercorradi.childImageSharp.fluid} />
          </Col>
          <Col md={8}>
            <h3>
              Olivier Corradi,
              <span className="function">&nbsp;Founder</span>
            </h3>
            <p>
              Olivier is a French/Danish machine learning engineer and
              full stack developer who created
              {' '}
              <Link outward href="https://www.electricitymap.org" target="_blank">electricityMap</Link>
              {' '}
              as part of Tomorrow.
              He previously managed data science and engineering at French AI
               startup Snips, hiring the first 30 employees.
              He studied mathematical statistics at the Technical University of
               Denmark and general engineering at Ecole Centrale Paris.
            </p>
            <div>
              <Link outward href="https://twitter.com/corradio" target="_blank">
                <FontAwesomeIcon size="lg" color="black" icon={faTwitter} />
              </Link>
              &nbsp;&nbsp;
              <Link outward href="https://www.linkedin.com/in/oliviercorradi" target="_blank">
                <FontAwesomeIcon size="lg" color="black" icon={faLinkedin} />
              </Link>
              &nbsp;&nbsp;
              <Link outward href="https://medium.com/@oliviercorradi" target="_blank">
                <FontAwesomeIcon size="lg" color="black" icon={faMedium} />
              </Link>
            </div>
          </Col>
        </TeamRow>

        <TeamRow>
          <Col md={4}>
            <TeamImg fluid={data.olivierbaumann.childImageSharp.fluid} />
          </Col>
          <Col md={8}>
            <h3>
              Olivier Baumann,
              <span className="function">&nbsp;Co-Founder</span>
            </h3>
            <p>
              Olivier is a Swiss/Brazilian designer and developer.
            
              A stats-nerd PhD dropout turned visual artist, art director and developer.
            
              He studied comparative international studies and social psychology
               at ETH and University of Zurich.
            </p>
            <div>
              <Link outward href="https://twitter.com/obauma" target="_blank">
                <FontAwesomeIcon size="lg" color="black" icon={faTwitter} />
              </Link>
              &nbsp;&nbsp;
              <Link outward href="https://www.linkedin.com/in/olivier-baumann/" target="_blank">
                <FontAwesomeIcon size="lg" color="black" icon={faLinkedin} />
              </Link>
            </div>
          </Col>
        </TeamRow>

        <TeamRow>
          <Col md={4}>
            <TeamImg fluid={data.trevorhinkle.childImageSharp.fluid} />
          </Col>
          <Col md={8}>
            <h3>
              Trevor Hinkle,
              <span className="function">&nbsp;Business Development</span>
            </h3>
            <p>
            Trevor is an American product manager and marketer.

              He previously spent 5 years working in digital strategy, UX design, and digital marketing roles in the US, UK, and South Africa.

              He studied climate change mitigation and adaptation at the University of Copenhagen and business analytics at the University of Virginia.
            </p>
            <div>
              <Link outward href="https://www.linkedin.com/in/trevorhinkle/" target="_blank">
                <FontAwesomeIcon size="lg" color="black" icon={faLinkedin} />
              </Link>
            </div>
          </Col>
        </TeamRow>

        <TeamRow>
          <Col md={4}>
            <TeamImg fluid={data.martincollignon.childImageSharp.fluid} />
          </Col>
          <Col md={8}>
            <h3>
              Martin Collignon,
              <span className="function">&nbsp;COO</span>
            </h3>
            <p>
              Martin is a French growth and operations manager.

              He previously was at Google, Uber and Lix Technologies.

              Martin enjoys bouldering and &quot;researching&quot; on Product Hunt, Hacker News and Reddit.

              He is also part of the Youth Climate Council on the Danish Ministry of Climate.
            </p>
            <div>
              <Link outward href="https://twitter.com/martincollignon" target="_blank">
                <FontAwesomeIcon size="lg" color="black" icon={faTwitter} />
              </Link>
              &nbsp;&nbsp;
              <Link outward href="https://www.linkedin.com/in/martincollignon/" target="_blank">
                <FontAwesomeIcon size="lg" color="black" icon={faLinkedin} />
              </Link>
            </div>
          </Col>
        </TeamRow>

        <TeamRow>
          <Col md={4}>
            <TeamImg fluid={data.felixqvist.childImageSharp.fluid} />
          </Col>
          <Col md={8}>
            <h3>
              Felix Qvist,
              <span className="function">&nbsp;Software Engineer Intern</span>
            </h3>
            <p>
              Felix is a Danish full stack developer.

              He is currently studying Software Development at the IT University of Copenhagen.

              Felix enjoys exploring new programming languages, frameworks, and platforms.

              Before joining Tomorrow, Felix worked with DevOps tools at a listed Danish software company.
            </p>
            <div>
              <Link outward href="https://www.linkedin.com/in/felix-qvist-a2a27b162/" target="_blank">
                <FontAwesomeIcon size="lg" color="black" icon={faLinkedin} />
              </Link>
            </div>
          </Col>
        </TeamRow>  
        
      </GridContainer>
    )}
  />
);

export default TeamMembers;
