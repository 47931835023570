import React from 'react';
import styled /* , { keyframes } */ from 'styled-components';
import { Parallax } from 'react-parallax';

import theme from '../../utils/themeconstants';
import StageContainer from '../elements/stageContentContainer';
import LeadText from '../elements/leadtext';
import Link from '../elements/link';


const Gradient = styled.div`
  /* height: 100vh; */
  width: 100%;
  position:relative;
  top: 0;
  overflow: hidden;
`;

const StageTitle = styled.div`
  height: 100vh;
  display:flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: ${theme.space.lg}px;
  h1,h2, p {
    color: black;
    transform: translateZ(600px);
    z-Index: 999;
    text-align: left;
  }
`;

const Circles = styled.div`
    height: 100vh;
    width: 100%;
    position: absolute;
    perspective: 1000px;
    z-index: 1;
    .para {
    position: absolute !important;
    overflow: visible !important;
    height: 100%;
    width: 100vw;
    }
`;

const Circle = styled.div`
    position: absolute;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: ${props => props.size / 2}px;
    background-image: linear-gradient(to top right, #FD712D, #FFBE1D);
    /* background-image: linear-gradient(to top right, rgba(255,255,255,0.8), #F6F9FC); */
    transform: translateZ(0);
    &.circle1 {
      box-shadow: -40px 40px 70px 40px rgba(0,0,0,0.15);
      @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
        box-shadow: -20px 20px 35px 20px rgba(0,0,0,0.15);
        width: ${props => props.size / 2}px;
        height: ${props => props.size / 2}px;
      }
    }
    &.circle2 {
      right: -35%;
      top: -25%;
      box-shadow: -40px 40px 140px 40px rgba(0,0,0,0.15);
      @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
        box-shadow: -20px 20px 70px 20px rgba(0,0,0,0.15);
        width: ${props => props.size / 2}px;
        height: ${props => props.size / 2}px;
        right: -45%;
        top: 15%;
      }
    }
    &.circle3 {
      right: 50%;
      top: 62%;
      box-shadow: -20px 30px 40px 10px rgba(0,0,0,0.15);
      @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
        box-shadow: -10px 15px 20px 5px rgba(0,0,0,0.15);
        width: ${props => props.size / 2}px;
        height: ${props => props.size / 2}px;
        right: 50%;
        top: 70%;
      }
    }
`;

class StageTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: undefined,
    };
  }

  updateDimensions = () => {
    if (typeof document !== 'undefined') {
      const body = document.getElementsByTagName('body')[0];
      const height = window.innerHeight
        || document.documentElement.clientHeight || body.clientHeight;
      this.setState({ windowHeight: height });
    }
  }

  componentWillMount = () => {
    this.updateDimensions();
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const { windowHeight } = this.state;
    const { children } = this.props;
    const circlesize = windowHeight / 2;

    return (
      <Gradient>
        <Circles>
          <Parallax
            strength={200}
            bgImage="none"
            bgImageStyle={{ display: 'none' }}
            className="para"
            renderLayer={percentage => (
              <div>
                <Circle
                  className="circle1"
                  size={circlesize}
                  style={{
                    position: 'absolute',
                    transform: `rotate3d(0, 1, 0, ${(percentage - 0.5) * -90}deg) translateZ(200px)`,
                  }}
                />
              </div>
            )}
          />
          <Parallax
            strength={200}
            bgImage="none"
            bgImageStyle={{ display: 'none' }}
            className="para"
            renderLayer={percentage => (
              <div>
                <Circle
                  className="circle2"
                  size={circlesize * 2}
                  style={{
                    transform: `rotate3d(0, 1, 0, ${(percentage - 0.5) * 120}deg)`,
                  }}
                />
              </div>
            )}
          />
          <Parallax
            strength={200}
            bgImage="none"
            bgImageStyle={{ display: 'none' }}
            className="para"
            renderLayer={percentage => (
              <div>
                <Circle
                  className="circle3"
                  size={circlesize * 0.8}
                  style={{
                    transform: `rotate3d(0, 1, 0, ${(percentage) * 65}deg) translateZ(-100px)`,
                  }}
                />
              </div>
            )}
          />

        </Circles>
        <StageContainer>
          <StageTitle>
            <h1>Building for Tomorrow</h1>
            <p>
              We are a group of practical idealists - trying to do what’s right and what’s possible. For us this means using our talents, our time and ambitions to help reduce our collective impact on climate change.
            </p>
          </StageTitle>
          {children}
        </StageContainer>
      </Gradient>
    );
  }
}

export default StageTeam;
