import React from 'react';
import styled from 'styled-components';

import theme from '../../utils/themeconstants';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ContentContainer from '../../components/elements/pageContentContainer';
import Section from '../../components/elements/section';
import TeamMembers from '../../components/teammembers';

import Stage from '../../components/pagecomponents/team_stage';


const PageContent = styled.div`
  position: relative;
  z-index: 999;
`;

const TeamPage = () => (
  <Layout parent="company" dark>
    <SEO title="Tomorrow - Team" keywords={['gatsby', 'application', 'react']} />
    <Stage>
      <PageContent>
        <ContentContainer>
          <Section title="Who we are">
            <TeamMembers />
          </Section>
        </ContentContainer>
      </PageContent>
    </Stage>
  </Layout>
);

export default TeamPage;
